import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route, useLocation, withRouter } from 'react-router-dom';
import Navbar from './container/NavBar';
// import FavouriteManager from './container/FavouriteManager';
import Footer from './component/Footer';
import Loader from './component/Loader';
import { PopupControl } from 'component/PopupControl/PopupControl';

import { convertNullToUndefined, generateSEO, updateCanonicalTag } from './helpers';

const HomePage = React.lazy(() => import('./container/Home'));
const LocationPage = React.lazy(() => import('./container/Location'));
const CateringPage = React.lazy(() => import('./container/Catering'));
const AboutPage = React.lazy(() => import('./container/About'));
const AllergiesPage = React.lazy(() => import('./container/Allergies'));
const CharityPage = React.lazy(() => import('./container/Charity'));
const ContactPage = React.lazy(() => import('./container/ContactPage'));
const DataPrivacy = React.lazy(() => import('./container/DataPrivacy'));

function App({ history }) {
  const [data, setPageData] = useState(null);
  const location = useLocation();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_DOMAIN}/all`)
      .then(response => response.json())
      .then(data => {
        setPageData(convertNullToUndefined(data));
      }).catch(error => {
        // @todo handle error
        console.log('Something went wrong');
      });

    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  if (!data) {
    return <Loader></Loader>
  }

  const { Home = {}, CoreData = {}, About = {}, LocationArlington = {}, LocationWestNewton = {},LocationBoston = {}, LocationDedham = {}, Catering = {}, Charity = {} } = data;
  return (
    <Suspense fallback={<Loader></Loader>}>
      <div className="App">
        {/* {Popup && !Popup.Disabled && <PopupControl key={location.pathname} cmsData={Popup}></PopupControl>} */}
        <Navbar cmsData={CoreData} />
        <Switch>

          <Route exact path="/about" component={() => <>
            <AboutPage cmsData={About} />
          </>} />
          <Route exact path="/arlington" component={() =>
            <>
              <LocationPage cmsData={LocationArlington} location="arlington" />
            </>} />
          <Route exact path="/west-newton" component={() =>
            <>
              <LocationPage cmsData={LocationWestNewton} location="newton" />
            </>} />
          <Route exact path="/dedham" component={() =>
            <>
              <LocationPage cmsData={LocationDedham} location="dedham" />
            </>} />
            <Route exact path="/boston" component={() =>
            <>
              <LocationPage cmsData={LocationBoston} location="boston" />
            </>} />
          <Route exact path="/catering" component={() =>
            <>
              <CateringPage cmsData={Catering} />
            </>} />

          <Route exact path="/allergies" component={() =>
            <>
              <AllergiesPage cmsData={Catering} />
            </>} />

          <Route exact path="/charity" component={() =>
            <>
              <CharityPage cmsData={Charity} />
            </>} />

          <Route exact path="/contact" component={() =>
            <>
              <ContactPage />
            </>
          } />
            <Route exact path="/data-privacy" component={() =>
            <>
              <DataPrivacy />
            </>
          } />

          <Route path="/" component={() =>
            <>
              <HomePage cmsData={Home} />
            </>} />
        </Switch>
        <Footer cmsData={CoreData} />
        {/* <FavouriteManager CoreData={CoreData} Rooms={Rooms.Rooms} LMS={Specials.LastMinuteSpecials} Packages={Specials.Promotions} /> */}
      </div>
    </Suspense>
  );
}

export default withRouter(App);
